exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-catalog-accessories-js": () => import("./../../../src/pages/catalog/accessories.js" /* webpackChunkName: "component---src-pages-catalog-accessories-js" */),
  "component---src-pages-catalog-index-js": () => import("./../../../src/pages/catalog/index.js" /* webpackChunkName: "component---src-pages-catalog-index-js" */),
  "component---src-pages-catalog-marina-m-1-js": () => import("./../../../src/pages/catalog/marina-m1.js" /* webpackChunkName: "component---src-pages-catalog-marina-m-1-js" */),
  "component---src-pages-catalog-marina-plastic-js": () => import("./../../../src/pages/catalog/marina-plastic.js" /* webpackChunkName: "component---src-pages-catalog-marina-plastic-js" */),
  "component---src-pages-catalog-modules-js": () => import("./../../../src/pages/catalog/modules.js" /* webpackChunkName: "component---src-pages-catalog-modules-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

